import { gql } from '@apollo/client'
import PaymentOption from 'types/PaymentOption'
import { uppercaseUnderscoreLocale } from './locale-helpers'
import { getApolloClient } from './apollo-client'

export const getPaymentOptions = async (locale: string, country: string): Promise<PaymentOption[]> => {
  // if Canada get payment options for US
  const lang = locale === 'en-ca' ? 'en_US' : uppercaseUnderscoreLocale(locale)
  const client = await getApolloClient()
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: gql`
        query {
          getPaymentOption(lang: ${lang}, locale: "${country}", userId: "1") {
            description
            image {
              height
              url
              width
            }
            name
            type
          }
        }
      `,
  })

  return data.getPaymentOption as PaymentOption[]
}
