import Image from 'next/image'
import { FC } from 'react'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  countryCode: string
  className?: string
}

const Flags: FC<PropTypes> = ({ countryCode, className }) => {
  const localizedIcon = `/icons/flags/${countryCode}.svg`
  return (
    <Image
      src={localizedIcon}
      width={22}
      height={22}
      alt={`Flag for ${countryCode}`}
      className={className}
      unoptimized
      sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
      loading="lazy"
      fetchPriority="low"
    />
  )
}

export default Flags
