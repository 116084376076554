import { FC } from 'react'
import { useModalState } from '../../context/ModalStateContext'
import Modal from '../modal/Modal'
import * as css from './LanguageRegionPickerModal.css'
import LanguageRegionPicker from './LanguageRegionPicker'
import { PageAlternateLink } from 'context/LinkContext'
import Box from 'atomic/components/atoms/box/Box'
import Button from 'atomic/components/atoms/button/Button'
import LanguageIcon from 'components/icons/LanguageIcon'
import DownArrow from 'components/icons/DownArrow'
import UpArrow from 'components/icons/UpArrow'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import Image from 'next/image'

type LanguageRegionPickerModalProps = {
  pageAlternates?: PageAlternateLink[]
}

export const LanguageRegionPickerButton: FC = () => {
  const { t } = useSafeTranslation()
  const { showLanguageRegionModal, openLanguageRegionModal } = useModalState()

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'xl'} style={{ width: '60%' }}>
      <Button className={css.button} onClick={() => openLanguageRegionModal('footer')}>
        <LanguageIcon />
        {t('footer_language_title')}
        {!showLanguageRegionModal && <DownArrow dark strokeWidth={2} />}
        {showLanguageRegionModal && <UpArrow dark strokeWidth={2} />}
      </Button>
    </Box>
  )
}

export const LanguageRegionPickerGlobe: FC = () => {
  const { openLanguageRegionModal } = useModalState()

  return (
    <Button
      variant="toggle"
      className={css.changeLanguageRegionBtn}
      onClick={() => openLanguageRegionModal('header')}
      aria-label="language-region-picker"
    >
      <Image src="/icons/change-language.svg" alt="language" width={24} height={24} />
    </Button>
  )
}

const LanguageRegionPickerModal: FC<LanguageRegionPickerModalProps> = ({ pageAlternates }) => {
  const { showLanguageRegionModal, closeLanguageRegionModal } = useModalState()

  return (
    <Modal className={css.container} show={showLanguageRegionModal} onClosed={() => closeLanguageRegionModal(false)}>
      <LanguageRegionPicker pageAlternates={pageAlternates} />
    </Modal>
  )
}

export default LanguageRegionPickerModal
