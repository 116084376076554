export const underscoreSeparatedLocale = (locale: string): string => locale.replace('-', '_')
export const dashSeparatedLocale = (locale: string): string => locale.replace('_', '-')
export const uppercaseCountryLocale = (locale: string): string => {
  const splitted = locale.split('-')
  return `${splitted[0]}-${splitted[1].toUpperCase()}`
}

/**
 * Convert locale from i.e. 'en-us' to 'en_US'
 * @param {string} locale - Locale, e.g. 'en-us' from router.locale
 * @returns {string} Locale with underscore, e.g. 'en_US', to be used in backend queries
 */
export const uppercaseUnderscoreLocale = (locale: string): string => {
  const splitted = locale.split('-')
  return `${splitted[0]}_${splitted[1].toUpperCase()}`
}
