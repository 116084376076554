import { useCookieContext } from 'context/CookieProvider'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import PaymentOption from 'types/PaymentOption'
import { getPaymentOptions } from 'utils/get-payment-options'

const usePaymentOptions = (): PaymentOption[] => {
  const router = useRouter()
  const { cookieCountry } = useCookieContext()
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>(null)

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      try {
        const paymentOptionsData = await getPaymentOptions(router.locale, cookieCountry)
        setPaymentOptions(paymentOptionsData)
      } catch {}
    }
    fetchPaymentOptions()
  }, [])

  return paymentOptions
}

export default usePaymentOptions
