import { FC, useState } from 'react'
import Link from 'next/link'
import { Box, Carousel, Text } from 'atomic'
import { TopBanner } from 'context/LinkContext'
import TrustpilotStars from 'components/trustpilot/TrustpilotStars'
import styles from './TopBanners.module.scss' // see comment in .scss file for more info why this and not vanilla extract
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import * as gtag from 'lib/gtag'

type PropTypes = {
  topBanners: TopBanner[]
}

const TopBanners: FC<PropTypes> = ({ topBanners }) => {
  if (!topBanners || topBanners.length === 0) return null

  const { t } = useSafeTranslation()
  const [copiedText, setCopiedText] = useState('')
  // State to manage notification visibility
  const [showNotification, setShowNotification] = useState(false)

  const handleClick = async (dataType: string, dataValue: string) => {
    gtag.logEvent('top_banner_click', { data_type: dataType, data_value: dataValue })

    if (dataType == 'code') {
      try {
        setCopiedText(dataValue) // Copy text to clipboard
        await navigator.clipboard.writeText(dataValue)
        setShowNotification(true) // Show notification
        // Hide notification after 3 seconds
        setTimeout(() => setShowNotification(false), 3000)
      } catch (err) {}
    }
  }

  const handleCopyOnClick = () => {
    const text = t('code_copied')
    const partOfTextBefore = text.split('{{code}}')[0]
    const partOfTextAfter = text.split('{{code}}')[1]
    return (
      <>
        {partOfTextBefore}
        <Text as="span" style={{ fontWeight: 'bold' }}>
          {copiedText}
        </Text>
        {partOfTextAfter}
      </>
    )
  }

  return (
    <>
      <Carousel
        paginatorPosition="hidden"
        autoChangeInterval={topBanners.length > 1 ? 5000 : undefined}
        items={topBanners?.map((topBanner, index) => {
          // setting data-type and data-value along with class="top-banner" allows us to find the event(s) in analytics
          let dataType = null
          let dataValue = null
          if (topBanner.textToCopyOnClick) {
            dataType = 'code'
            dataValue = topBanner.textToCopyOnClick
          } else if (topBanner.url) {
            dataType = 'url'
            dataValue = topBanner.url
          }

          return (
            <Box
              key={index}
              display="flex"
              style={{ backgroundColor: topBanner.backgroundColor, cursor: topBanner.textToCopyOnClick || topBanner.url ? 'pointer' : 'default' }}
              paddingLeft="s"
              paddingRight="s"
              paddingTop="xxs"
              paddingBottom="xxs"
              justifyContent="center"
              gap="m"
              onClick={() => handleClick(dataType, dataValue)}
            >
              {topBanner.url && (
                <Link href={topBanner.url}>
                  <Box
                    margin="none"
                    fontSize="s"
                    fontWeight="semibold"
                    style={{ color: topBanner.textColor }}
                    className={styles.richTextParent}
                    dangerouslySetInnerHTML={{ __html: topBanner.text }}
                  />
                </Link>
              )}
              {!topBanner.url && (
                <Box
                  margin="none"
                  fontSize="s"
                  fontWeight="semibold"
                  style={{ color: topBanner.textColor }}
                  className={styles.richTextParent}
                  dangerouslySetInnerHTML={{ __html: topBanner.text }}
                />
              )}
              {topBanner.showTrustpilotStars && (
                <Box style={{ alignSelf: 'flex-end', height: '20px' }}>
                  <TrustpilotStars width={84} height={20} />
                </Box>
              )}
            </Box>
          )
        })}
      />
      {/* "X has been copied"-notification */}
      {showNotification && (
        <Box
          position={'fixed'}
          backgroundColor={'white'}
          color={'malm0'}
          padding={'xxs'}
          paddingLeft={'xs'}
          paddingRight={'xs'}
          borderRadius={'s'}
          fontSize={'s'}
          fontWeight={'semibold'}
          style={{
            top: '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            border: '1px solid #ddd',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          {handleCopyOnClick()}
        </Box>
      )}
    </>
  )
}

export default TopBanners
