import dynamic from 'next/dynamic'
import { FC } from 'react'

import { useModalState } from '../../context/ModalStateContext'
import Modal from '../modal/Modal'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import styles from './GetAppModal.module.scss'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { Box, Container, Heading, Text } from 'atomic'

const AppBadges = dynamic(() => import('../appbadges/AppBadges'), { ssr: false })

const GetAppModal: FC = () => {
  const { showGetAppModal, setShowGetAppModal } = useModalState()
  const { t } = useSafeTranslation()
  return (
    <Modal className={styles.container} show={showGetAppModal} onClosed={() => setShowGetAppModal(false)}>
      <Container>
        <Box position="relative" marginBottom="m" style={{ minWidth: '136px', minHeight: '136px' }}>
          <Illustration name={'hand-4'} height={136} />
        </Box>
        <Box>
          <Heading as="h2" fontSize="xl" marginBottom="m">
            {t('get_app_modal_title')}
          </Heading>
          <Text>{t('get_app_modal_text')}</Text>
        </Box>
      </Container>
      <AppBadges style={{ marginRight: 10 }} />
    </Modal>
  )
}

export default GetAppModal
